<template>
    <div>
        <Breadcrumb :options="breadcrumb" />
        <div class="headline">
            <h1 class="text-xl font-bold" v-if="Search.category && Search.category.id != 1">{{ Search.category.name }}</h1>
            <h1 class="text-xl font-bold mt-2" v-else>Ihre Suche nach "{{ Search.query }}"</h1>
            <FilterCarousel :options="getFilterCarouselOptions" @item-clicked="toggleFilter" />
        </div>
        <div class="productListing">
            <div class="productListing-filter">
                <div class="filterContainer">
                    <div class="filters">
                        <div class="filtersHeadline">Filter</div>
                        <SearchFilter />
                    </div>
                </div>

            </div>
            <div class="productListing-result">
                <div class="resultsSetting">
                    <div class="resultsCount">{{ formatNumbers.format(Search.total) }} Produkte</div>
                    <div class="resultsSorting">

                        <button class="filter-btn" @click="Site.showFilter = true">
                            <BaseIcon name="Filter" style="margin-right: 4px;" />
                            Filter
                            <SearchFilterModal v-if="Site.showFilter" />
                        </button>

                        <span class="sort-btn" style="">

                            <Dropdown :items="Search.sort_by_options" prefix="Sortiert nach: " v-model="Search.sort_by" />
                        </span>

                    </div>
                </div>
                <div v-if="filters.length >= 1" class="resultsFilter"
                    style="border-top: 1px solid #dfe4eb;padding-top:12px;">
                    <ScrollX>
                        <div style="display: flex;">
                            <div style="display: inline-block; white-space: nowrap;">
                                <label v-if="Search.query" class="tagBtn"
                                    @click="Search.query = null; handleFilterChange()">
                                    <span style="line-height: 1;margin-right: 4px;display: inline-block;">
                                        "{{ Search.query }}"</span>
                                    <span
                                        style="display: block;flex-shrink: 0;position: relative;height: 16px;width: 16px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                            viewBox="0 0 512 512"
                                            style="height: 100%;left: 0;position: absolute;top: 0;width: 100%;">
                                            <path
                                                d="M289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34z"
                                                fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </label>

                                <label v-for=" filter  in  filters " class="tagBtn"
                                    @click=" toggleFilter(filter.key, filter.optionId)">
                                    <span style="line-height: 1;margin-right: 4px;display: inline-block;">
                                        {{ filter.optionName }}</span>
                                    <span
                                        style="display: block;flex-shrink: 0;position: relative;height: 16px;width: 16px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                            viewBox="0 0 512 512"
                                            style="height: 100%;left: 0;position: absolute;top: 0;width: 100%;">
                                            <path
                                                d="M289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34z"
                                                fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </label>
                            </div>
                            <button class="clearFilterBtn">Alle Filter löschen</button>
                        </div>
                    </ScrollX>

                </div>
                <div class="results">
                    <div class="resultItem" v-for=" product  in  Search.products " :key="product.id.raw">
                        <router-link :to="{ name: 'product', params: { id: product.id.raw } }">

                            <div class="itemCard">
                                <div class="cardContent">
                                    <div class="cardImgContainer">
                                        <div class="cardImg">
                                            <img>
                                            <img v-if="product.image.raw" width="160" height="160"
                                                :src="product.image.raw" />
                                            <img v-else src="@/assets/no-img.jpeg" width="160" height="160" />
                                        </div>
                                    </div>
                                    <div class="productTitle">{{ product.title.raw }}</div>

                                </div>
                                <div class="cardFooter">
                                    <span class="cardPrice"><span v-if="product.offers.length >= 1">ab</span> {{
                                        formatCurrency.format(product.price_amount.raw / 100)
                                    }}*</span>
                                </div>
                            </div>

                        </router-link>
                    </div>
                </div>
                <div style="text-align: center;padding-top:16px;">
                    <BaseButton block variant="secondary-outline" @click=" Search.loadMore">
                        <BaseIcon name="AddCircleOutline" /> Weitere Produkte anzeigen
                    </BaseButton>
                </div>

            </div>
        </div>
    </div>
</template>

<style lang="scss">
.filter-btn,
.sort-btn {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
    position: relative;
    flex-shrink: 0;
    line-height: 1;
    font-size: 0.875rem;
    height: 32px;
    white-space: nowrap;
    transition: box-shadow 300ms ease, background 300ms ease, color 300ms ease, transform 300ms ease;
    background: transparent center;
    -webkit-font-smoothing: antialiased;
    font-weight: var(--weight-medium);
    box-shadow: 0 0 0 1px inset transparent;
    justify-content: space-between;
    line-height: 1.1;
    border: none;
    margin: 0;
    padding: 9px 12px 9px 16px;
    border-radius: 16px;

    &:hover {
        background: $grayscale30;
        box-shadow: 0 0 0 1px inset $grayscale30;
    }
}

.filter-btn {
    display: inline-flex;

    @media (min-width: 1024px) {
        display: none;
    }
}

.productTitle {
    font-weight: inherit;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -webkit-font-smoothing: antialiased;
    display: -webkit-inline-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    vertical-align: bottom;
    word-break: break-word;
    font-size: 13px;
    line-height: 18px;

    @media (min-width: 1024px) {
        padding: 0 16px;
    }
}

.cardImg {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    justify-content: center;
    padding: 8px;
    padding-top: 16px;
    width: 100%;
    height: 160px;
    overflow: hidden;
    transition: filter .2s linear;

    &::after {
        background-color: $overlay-image;
        content: "";
        position: absolute;
        inset: 0px;
    }

    @media (min-width: 1024px) {
        &::after {
            content: none;
        }
    }
}

.cardImgContainer {
    position: relative;
    background: #fefefe;

    @media (min-width: 1024px) {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }
}

.cardPrice {
    font-size: 16px;
    font-weight: bold;
    line-height: 23px;
    -webkit-font-smoothing: antialiased;
    white-space: nowrap;
}

.cardContent {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 12px;
    flex-direction: column;
    display: flex;
    -webkit-column-gap: 12px;
    column-gap: 12px;
    row-gap: 12px;
    flex: 1 0 auto;
}

.cardFooter {
    flex-direction: row;
    display: flex;
    align-items: flex-end;

    @media (min-width: 1024px) {
        padding: 0 16px;
    }
}

.itemCard {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    background-color: #FFF;
    border-radius: 4px;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    padding-bottom: 16px;
    border-width: 0;

    @media (min-width: 1025px) {
        box-shadow: 0 2px 6px 0 rgba(32, 76, 136, 0.15);
        transition: box-shadow .2s ease;
    }
}

.resultItem {
    position: relative;
    height: 100%;
    grid-column: span 1;
    grid-row: span 1;
}

.resultItem a {
    color: inherit;
}

.resultItem a:hover img {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}

.resultItem img {
    flex-shrink: 0;
    transition: opacity .2s ease-in-out;
    max-width: 100%;
    height: auto;
    align-self: center;
    transition: transform 300ms;
}

.results {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: 1fr 1fr;

    -webkit-column-gap: 2px;
    column-gap: 2px;
    row-gap: 16px;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: 1024px) {
        -webkit-column-gap: 16px;
        column-gap: 16px;
        row-gap: 16px;
    }
}

.tagBtn {
    border: none;
    margin: 0;
    padding: 6px 8px 6px 12px;
    background-color: #ebeff5;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px;

    &:hover {
        background-color: $grayscale40;
    }
}

.clearFilterBtn {
    padding-top: 9px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 9px;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    position: relative;
    flex-shrink: 0;
    line-height: 1;
    font-size: 0.85rem;
    height: 32px;
    white-space: nowrap;
    transition: box-shadow 300ms ease, background 300ms ease, color 300ms ease, transform 300ms ease;
    background: transparent center;
    box-shadow: 0 0 0 1px inset transparent;
    border: none;
    margin: 0px;
}

.sortOption {
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-width: 174px;
}

@media (min-width: 360px) {
    .sortOption {
        max-width: 100%;
    }
}

.resultsSorting {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    -webkit-column-gap: 4px;
    column-gap: 4px;
    row-gap: 4px;
}

.resultsCount {
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 32px;
    min-width: 0;
    -webkit-font-smoothing: antialiased;
    max-width: 700px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    font-weight: bold;
    line-height: 24px;
}

.resultsSetting {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}



.productListing-filter {
    position: relative;
    padding-top: 40px;
}

.productListing-result {
    position: relative;
    padding-top: 8px;
    flex: 1;
    max-width: 100%;

    @media (min-width: 1024px) {
        padding-top: 40px;
    }
}

.productListing {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    padding-bottom: 40px;

    &::before {
        content: '';
        background-color: $grayscale10;
        height: 1px;
        left: 50%;
        position: absolute;
        width: calc(100vw - 20px);
        transform: translate(-50%);

        @media (min-width: 1024px) {
            height: 100%;
        }
    }

}

.headline {
    position: relative;
    //    display: flex;
    margin: 0 8px 16px 0;
}

.headline h1 {
    padding: 0;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 8px;
    letter-spacing: -0.3px;
    display: inline;
    margin: 0 4px 0 0;
}

@media (min-width: 1024px) {
    .headline h1 {
        display: block;
        margin: 0;
    }
}

.price .price-amount {
    font-weight: bold;
    color: #f60;
}

.price .price-prefix {
    font-size: 0.8rem;
    color: #f60;
}

.stretched-link:after {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.footer {
    display: flex;
    justify-content: space-between;
}
</style>

<script>
import { onMounted } from 'vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import { SearchStore } from '@/stores/Search';
import { SiteStore } from '@/stores/Site';
import FilterCarousel from '@/components/FilterCarousel.vue';
import SearchFilter from '@/components/SearchFilter.vue';
import SearchFilterModal from '@/components/SearchFilterModal.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import ScrollX from '@/components/ScrollX.vue';
import BaseButton from '@/components/BaseButton.vue';
import Dropdown from '@/components/Dropdown.vue';
import { watchSortBy } from '@/components/watchers/watchSortBy.js';

export default {
    setup() {
        const Search = SearchStore();
        const Site = SiteStore();
        onMounted(watchSortBy);
        return { Search, Site }
    },
    data() {
        return {
            breadcrumb: [],
            filters: [],
            formatCurrency: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }),
            formatNumbers: new Intl.NumberFormat('de-DE'),
            selectedSorting: null,
        }
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    computed: {
        getFilterCarouselOptions() {
            const options = [];
            if (this.Search.facets.categories && this.Search.facets.categories[0].data.length > 1) {
                this.Search.facets.categories[0].data.forEach((value) => {
                    const obj = this.Search.category.children.find(item => item.path == value.value);
                    options.push({
                        id: value.value,
                        group: 'categories',
                        name: obj.name,
                        count: value.count
                    });
                });
            }
            return options;
        }
    },
    methods: {
        toggleFilter(group, value) {

            const filter = this.Search.filter[group].values;
            const index = filter.indexOf(value);
            if (index > -1) {
                this.Search.filter[group].values.splice(index, 1);
            } else {
                if (group == "categories") {
                    this.Search.filter[group].values = [String(value)];
                } else {
                    this.Search.filter[group].values.push(String(value));
                }
            }
            this.handleFilterChange();
            return;

        },
        fetchData() {
            this.Search.page = 1;
            let params = this.$route.params;
            let query = this.$route.query;

            if (query.sort_by && this.Search.sort_by_options.find(item => item.value == query.sort_by)) {
                this.Search.sort_by = query.sort_by;
            } else {
                this.Search.sort_by = 'relevance';
            }

            if (query.maxPrice) {
                this.Search.filter.price_amount.value = Number(query.maxPrice);
            } else {
                this.Search.filter.price_amount.value = null;
            }

            if (params.categories && params.categories.length >= 1) {
                this.Search.filter.categories.values = [params.categories.join('/')];
            } else {
                this.Search.filter.categories.values = [];
            }

            if (query.brand) {
                this.Search.filter.brand.values = query.brand.split(',');
            } else {
                this.Search.filter.brand.values = [];
            }

            if (query.merchants) {
                this.Search.filter.merchants.values = query.merchants.split(',');
            } else {
                this.Search.filter.merchants.values = [];
            }

            if (query.delivery_time_normalized) {
                this.Search.filter.delivery_time_normalized.values = query.delivery_time_normalized.split(',').map(Number);
            } else {
                this.Search.filter.delivery_time_normalized.values = []
            }

            if (query.q) {
                this.Search.query = query.q;
            }

            this.Search.execute().then(() => {
                this.breadcrumb = [];
                if (this.Search.category) {
                    this.Search.category.breadcrumb.forEach((crumb) => {
                        let categories = [];
                        if (crumb.path.includes('/')) {
                            categories = crumb.path.split('/');
                        } else {
                            categories.push(crumb.path)
                        }
                        this.breadcrumb.push({
                            name: crumb.name,
                            link: this.$router.resolve({ name: 'category', params: { categories: categories } }).href
                        });
                    });
                }
                this.updateFilters();
                this.updateMetaInfo();
            });
        },
        handleFilterChange() {
            this.$router.push(this.Search.getRouting());
        },
        updateMetaInfo() {
            if (this.Search.category) {
                this.Site.meta.title = 'Vergleich ' + this.Search.category.name + ' Preise bei ShoppingConnection';
            } else if (this.Search.query.length >= 1) {
                this.Site.meta.title = '"' + this.Search.query + '" @ ShoppingConnection';
            }
        },
        updateFilters() {
            this.filters = [];

            Object.keys(this.Search.filter).forEach(filterKey => {
                if (filterKey == "prices") {
                    return;
                }

                if (!Array.isArray(this.Search.filter[filterKey])) {
                    return;
                }

                if (this.Search.filter[filterKey]) {
                    this.Search.filter[filterKey].forEach((value, index) => {
                        const obj = this.Search.facets[filterKey].find(el => {
                            return value == el.id
                        });
                        if (obj) {
                            this.filters.push({
                                key: filterKey,
                                optionId: obj.id,
                                optionName: obj.name
                            })
                        }
                    })
                }
            });

        }
    },
    created() {
        this.fetchData();
    },
    watch: {
        '$route': 'fetchData'
    },
    components: {
        Breadcrumb,
        FilterCarousel,
        SearchFilter,
        BaseIcon,
        ScrollX,
        SearchFilterModal,
        BaseButton,
        Dropdown
    },
}
</script>

