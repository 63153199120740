<template>
    <div class="pageNavigation" :class="{ 'pageNavigation--activated': (selected) }">

        <div class="pageNavigation-top">
            <ul>
                <template v-for="category in categories">
                    <li v-if="category.id != 999999">
                        <button class="pageNavigation-topBtn" @click="selectCategory(category)"
                            :class="{ active: (category.id == selected) }">
                            <span class="pageNavigation-topIconContainer">
                                <BaseIcon :name="category.icon" />
                            </span>
                            <div style="padding-left: 8px;">{{ category.name }}</div>
                        </button>
                    </li>
                </template>
            </ul>
        </div>
        <div class="pageNavigation-sub">
            <button class="pageNavigation-showAll" @click="selectCategory(null)">
                <BaseIcon name="ChevronBack" />All categories
            </button>
            <a href="#" class="pageNavigation-heading">{{ headline }}</a>
            <div class="pageNavigation-grid">
                <div class="pageNavigation-gridItem" v-for="category in children">
                    <div style="flex-direction: column;display: flex;justify-content: center;gap: 16px;min-width: 120px;">
                        <!--
                        <a :href="generateUrl(category.path)">
                            <div class="pageNavigation-img">
                                <img aria-hidden="true" data-testid="category-card-image" class="xUAmIg3IAt pr-1qf1dpq"
                                    alt="Child Car Seats" width="138" height="138"
                                    srcset="https://www.pricerunner.com/category/276x276/cl369.jpg 2x"
                                    src="https://www.pricerunner.com/category/138x138/cl369.jpg">
                                <div class="pageNavigation-imgBg"></div>
                            </div>
                        </a>-->
                        <a :href="generateUrl(category.path)" style="padding-left: 16px;padding-right: 16px;width: 100%;font-weight: 600;font-size:
                                1rem;line-height: 20px;letter-spacing: -0.2px;">
                            {{ category.name }}
                        </a>
                    </div>
                    <div style="padding-top: 8px;padding-left: 16px;padding-right: 16px;">
                        <ul style="line-height: 1.5; font-size: 0.9rem;">
                            <li v-for="child in category.children"><a :href="generateUrl(child.path)">{{
                                child.name
                            }}</a></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<style lang="scss">
.pageNavigation-topIconContainer {
    background-color: #f6f6f6;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
    transition: background-color 200ms ease-out 100ms;
}

.pageNavigation-imgBg {
    position: absolute;
    background: $overlay-content;
    inset: 0px;
    border-radius: 4px;
}

.pageNavigation-img {
    padding: 16px;
    width: 100%;
    height: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    background-color: rgb(254, 254, 254);
}

.pageNavigation-gridItem {
    max-width: 100%;
    flex: 0 0 auto;
    padding-top: 24px;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;

    &>a {
        flex-direction: column;
        display: flex;
        justify-content: center;
        gap: 16px;
        min-width: 120px;
    }

    @media (min-width: 576px) {
        width: 50%;
    }

    @media (min-width: 768px) {
        width: 33.3333%;
    }
}

.pageNavigation-grid {
    flex-flow: row wrap;
    display: flex;
    width: calc(100% + 16px);
    margin-right: -8px;
    margin-left: -8px;
    padding-bottom: 40px;
}

.pageNavigation-heading {
    display: block;
    font-weight: $font-weight-heavy;
    font-size: 2.25rem;
    line-height: 42px;
    letter-spacing: -0.4px;
}

.pageNavigation {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
    max-width: 1380px;
    position: relative;
}

.pageNavigation--activated {
    .pageNavigation-top {
        display: none;
    }

    .pageNavigation-sub {
        display: block;
    }

    @media (min-width: 1024px) {
        .pageNavigation-top {
            display: block;
        }

        .pageNavigation-sub {
            display: block;
        }
    }
}

.pageNavigation-top {
    border-right: 1px solid $border20;
    flex-shrink: 0;
    width: 100%;
}

@media (min-width: 1024px) {
    .pageNavigation-top {
        min-width: 284px;
        width: auto;
    }
}

.pageNavigation-top ul {
    flex-direction: column;
    display: flex;
    -webkit-column-gap: 0;
    column-gap: 0;
    row-gap: 0;
}

.pageNavigation-top button {
    font-size: 1rem;
    line-height: 20px;
    padding: 10px 16px 10px 24px;
    text-align: left;
    transition: color 0.2s ease 0s;
    width: 100%;
    align-items: center;
    display: flex;

    &>span {
        color: $brand60;
    }

    &.active,
    &:hover {
        background-color: $background-secondary;
    }

    &.active .pageNavigation-topIconContainer,
    &:hover .pageNavigation-topIconContainer {
        background-color: $brand60;
        color: #FFF;
    }
}

.pageNavigation-sub {
    flex-grow: 1;
    padding: 16px 24px;
    display: none;
}

@media (min-width: 1024px) {
    .pageNavigation-sub {
        display: block;
        padding: 40px 24px;
    }
}

.pageNavigation-showAll {
    margin-bottom: 12px;
    font-size: 0.875rem;
}

@media (min-width: 1024px) {
    .pageNavigation-showAll {
        display: none;
    }
}
</style>

<script>
import NavigationData from '@/navigation.json';
import BaseIcon from '@/components/BaseIcon.vue';

export default {
    data() {
        return {
            categories: NavigationData,
            selected: null,
            headline: '',
            children: []
        };
    },
    created() {
        this.headline = this.categories[1].name;
        this.children = this.categories[1].children;
    },
    methods: {
        generateUrl(path) {
            let categories = [];
            if (path.includes('/')) {
                categories = path.split('/');
            } else {
                categories.push(path);
            }
            return this.$router.resolve({ name: 'category', params: { categories: categories } }).href
        },
        selectCategory(category) {
            if (category) {
                this.selected = category.id;
                this.headline = category.name;
                this.children = category.children;
            } else {
                this.selected = null;
            }
        }
    },
    components: {
        BaseIcon
    }
}
</script>