<template>
    <Teleport to="body">
        <div class="searchFilterModal">
            <div class="searchFilterModel-container">
                <div class="searchFilterModal-card">
                    <div class="searchFilterModal-header">
                        <div style="display: flex;flex: 1 1 0;justify-content: center;"></div>
                        <div style="flex: 6 1 0;">
                            <span style="font-weight: 500">Filter</span>
                        </div>
                        <div style="display: flex;flex: 1 1 0;justify-content: center;">
                            <button class="searchFilter-closeBtn" @click="Site.showFilter = false">
                                <BaseIcon name="Close" />
                            </button>
                        </div>
                    </div>
                    <div class="searchFilterModal-main">
                        <SearchFilter />
                    </div>
                    <div class="searchFilterModal-footer">
                        <button class="searchFilterModal-showBtn" @click="Site.showFilter = false">
                            Show {{ formatNumbers.format(Search.total) }} Products
                        </button>
                    </div>
                </div>

            </div>

        </div>
    </Teleport>

</template>

<script>
import { SiteStore } from '@/stores/Site';
import { SearchStore } from '@/stores/Search';
import SearchFilter from '@/components/SearchFilter.vue';
import BaseIcon from '@/components/BaseIcon.vue';

export default {
    setup() {
        const Site = SiteStore();
        const Search = SearchStore();
        return { Site, Search }
    },
    data() {
        return {
            formatNumbers: new Intl.NumberFormat('en-GB')
        }
    },
    methods: {
        toggleFilter(group, id) {
            const filter = this.Search.filter[group];
            if (typeof filter === 'string' || typeof filter === 'undefined') {
                this.Search.filter[group] = id;
                this.$router.push(this.Search.getRouting());
                return;
            }

            if (Array.isArray(filter)) {
                const index = filter.indexOf(id);
                if (index > -1) {
                    this.Search.filter[group].splice(index, 1);
                } else {
                    this.Search.filter[group].push(id);
                }
                this.$router.push(this.Search.getRouting());
                return;
            }
        },
    },
    components: {
        SearchFilter,
        BaseIcon
    }
}
</script>

<style lang="scss">
.searchFilter-closeBtn {
    margin-left: auto;
    height: 16px;
    border-radius: 50%;
    padding: 4px;
    height: 24px;

    &:hover {
        background: $grayscale30;
        box-shadow: 0 0 0 1px inset $grayscale30;
    }
}

.searchFilterModal {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    overscroll-behavior: none;
    pointer-events: all;
    touch-action: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 101;
    transition: transform .5s ease, opacity .5s ease;
    will-change: opacity;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    background-color: $overlay-primary;
}

.searchFilterModel-container {
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: $background-primary;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    bottom: -150px;
    margin: 0 auto;
    max-width: 500px;
    overflow: hidden;
    padding-bottom: 150px;
    right: 0;
    left: 0;
    z-index: 900;
}

.searchFilterModal-card {
    display: grid;
    grid-template-areas:
        "header"
        "main"
        "footer";
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    max-height: 90vh;
    overscroll-behavior: none;
    touch-action: auto;
}

.searchFilterModal-header {
    align-items: center;
    border-bottom: 1px solid $border20;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: flex;
    flex-wrap: nowrap;
    grid-area: header;
    justify-content: center;
    padding: 12px 16px;
    text-align: center;
}

.searchFilterModal-main {
    grid-area: main;
    overflow-y: auto;
    padding: 16px 16px 24px;
}

.searchFilterModal-footer {
    border-top: 1px solid $border20;
    display: flex;
    flex-direction: column;
    grid-area: footer;
    padding: 16px;
}

.searchFilterModal-showBtn {
    padding: 12px 16px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    position: relative;
    flex-shrink: 0;
    line-height: 1;
    font-size: $text-size-m;
    height: 40px;
    width: 100%;
    border-radius: 4px;
    white-space: nowrap;
    transition: box-shadow 300ms ease 0s, background 300ms ease 0s, color 300ms ease 0s, transform 300ms ease 0s;
    background: $brand60 center;
    -webkit-font-smoothing: antialiased;
    font-weight: $font-weight-medium;
    box-shadow: transparent 0px 0px 0px 1px inset;
    color: #FFF;

    &:hover {
        background: $brand80 radial-gradient(circle, transparent 1%, rgba(0, 0, 0, 0.1) 1%) center/15000%;
        box-shadow: transparent 0px 0px 0px 1px inset;
    }
}
</style>