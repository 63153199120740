<template>
    <form class="SearchForm" :class="{ 'SearchForm--active': focus }" @submit.prevent="onSubmit" action="/c" method="get">
        <div class="SearchForm-background"></div>
        <div class="SearchForm-field">
            <input class="SearchForm-input" aria-label="Wonach suchen Sie?" autocapitalize="off" autocomplete="off"
                autocorrect="off" maxlength="100" name="q" @focus="focus = true" @blur="focus = false"
                placeholder="Wonach suchen Sie?" spellcheck="false" type="text" v-model="query" />
            <div class="SearchForm-extension">
                <button aria-label="Search" class="SearchForm-button" type="submit">
                    <BaseIcon name="Search" />
                </button>
            </div>
        </div>
    </form>
</template>

<style lang="scss">
.SearchForm--active {
    & .SearchForm-background {
        opacity: 1;
        transition-delay: 0s;
        visibility: visible;
    }

    & .SearchForm-field {
        background-color: $grayscale0;
    }
}

.SearchForm {
    position: relative;
    z-index: 100;
}

.SearchForm-background {
    background-color: $overlay-primary;
    bottom: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    transition: visibility 0ms linear .2s, opacity .2s ease;
    visibility: hidden;
}

.SearchForm-field {
    position: relative;
    display: flex;
    border: 1px solid $border40;
    border-radius: 4px;
    outline: none;
    background-color: $background-secondary;
    transition: border-color .2s ease, background-color .2s ease;

    &:hover {
        border-color: $border60;
    }
}

.SearchForm-input {
    background: transparent;
    border-radius: 4px 0 0 4px;
    height: 44px;
    padding: 8px 16px;
    width: 100%;
    z-index: 1;
    outline: none;
}

.SearchForm-extension {
    align-content: center;
    align-items: center;
    border-radius: 0 4px 4px 0;
    display: flex;
    position: relative;
    font-size: $text-size-l;
}

.SearchForm-button {
    display: flex;
    background-color: transparent;
    border-radius: 4px;
    color: $brand60;
    margin-right: 4px;
    padding: 7px;
    transition: color .2s ease, background-color .2s ease;
}
</style>

<script>
import { ref, watchEffect } from 'vue'
import BaseIcon from '@/components/BaseIcon.vue';

export default {
    props: {
        initialValue: {
            type: String
        }
    },
    data() {
        return {
            focus: false
        }
    },
    setup() {
        const query = ref("");
        watchEffect(() => { /* console.log(query.value) */ }) //usable for suggested search
        return { query }
    },
    mounted() {
        if (this.initialValue) {
            this.query = this.initialValue;
        }
    },
    methods: {
        onSubmit(event) {
            this.focus = false;
            event.target.querySelector('.SearchForm-input').blur();
            this.$router.push({ name: 'search', query: { q: this.query } });
        }
    },
    components: {
        BaseIcon
    }
}
</script>