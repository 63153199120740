<template>
    <div class="feedbackModal" :class="{ 'feedbackModal--active': open }">
        <div class="feedbackModal-background" @click="open = false"></div>
        <button class="feedbackModal-btn" @click="open = !open" data-text="Feedback">
        </button>

        <div v-if="open"
            style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%); z-index: 2; max-width: 640px;">
            <form v-if="!submitted" class="feedbackModal-card" @submit.prevent="submit">
                <h1 style="font-size: 1.75rem;letter-spacing: -0.3px;">Danke, dass du uns helfen möchtest!</h1>
                <p style="padding-top:16px;">Wenn du deine E-Mailadresse eingibst, können wir uns bei weiteren Fragen
                    oder Hilfestellungen bei dir melden.</p>
                <div style="padding-top: 16px;">
                    <strong style="font-size: 0.875rem;line-height: 1.5em;">Dein Feedback</strong>
                    <textarea v-model="form.feedback"
                        style="display: block;width: 100%;height: 150px; border: 1px solid #DDD;padding: 0.5rem 1rem;line-height: 1.3em;border-radius: 4px;"></textarea>
                </div>
                <div style="padding-top: 16px;">
                    <strong style="font-size: 0.875rem;line-height: 1.5em;">Deine E-Mail</strong>
                    <input v-model="form.mail"
                        style="display: block;width: 100%; border: 1px solid #DDD;padding: 0.5rem 1rem;line-height: 1.3em;border-radius: 4px;"
                        type="text" placeholder="Falls du eine Antwort von uns möchtest" />
                </div>
                <div style="padding-top: 16px; display: flex;">
                    <div style="padding-right: 1rem;">
                        <BaseButton block type="submit" variant="primary">Absenden</BaseButton>
                    </div>
                    <div>
                        <BaseButton block variant="secondary-outline" @click.prevent="open = false">Abbrechen
                        </BaseButton>
                    </div>
                </div>
            </form>
            <div v-else class="feedbackModal-card">
                <h1 style="font-size: 1.75rem;letter-spacing: -0.3px;">Danke für deine Unterstützung!</h1>
                <div style="padding-top: 2rem;">
                    <BaseButton block variant="primary" @click.prevent="open = false">Weiter shoppen!</BaseButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue';
import axios from "axios";
export default {
    data() {
        return {
            open: false,
            submitted: false,
            form: {
                feedback: "",
                mail: ""
            }
        }
    },
    methods: {
        submit() {
            if (this.form.feedback.length <= 10) {
                return;
            }

            axios.post("/api/mail/feedback", this.form)
                .then((response) => {
                    this.form.feedback = '';
                    this.submitted = true;
                    setTimeout(() => {
                        this.submitted = false;
                        this.open = false;
                    }, 2000);
                })
                .catch((error) => {
                    console.log(error);
                    return;
                });
        }
    },
    components: {
        BaseButton
    }
}
</script>

<style lang="scss">
.feedbackModal {
    display: none;

    @media (min-width: 1440px) {
        display: block;
    }
}

.feedbackModal-btn {
    right: -25px;
    position: fixed;
    top: calc(50%);
    background-color: #FFF;
    z-index: 2;
    border: 1px solid $border60;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 0;
    height: 40px;
    padding: 0.5rem 1rem;
    transform: rotate(-90deg);

    &::before {
        color: $text-color-primary;
        content: attr(data-text);
        height: 40px;
        font-size: 0.875rem;
    }

    &:hover {
        border-color: $brand60;
    }
}

.feedbackModal-card {
    background-color: $background-primary;
    border-radius: 16px;
    max-height: 100%;
    overflow: hidden;
    position: relative;
    padding: 1rem 2rem;
}

.feedbackModal--active {
    & .feedbackModal-background {
        opacity: 1;
        transition-delay: 0s;
        visibility: visible;
    }
}

.feedbackModal-background {
    background-color: $overlay-primary;
    bottom: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    transition: visibility 0ms linear .2s, opacity .2s ease;
    visibility: hidden;
    z-index: 2;
}
</style>