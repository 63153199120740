<template>
    <span class="filter-title">{{ name }}</span>
    <div class="pb-8">
        <Slider v-model="selectedValue" :min="baseRange.min" :max="baseRange.max" :step="1" :connect="true"
            :format="selected => formatCurrency.format(selected)" :dragging="false"
            @update="$emit('update', filterKey, selectedValue)" tooltip-position="bottom" />
    </div>
</template>

<script setup>
import Slider from '@vueform/slider'
import { ref } from 'vue';

let { name, filterKey, baseRange, selected } = defineProps({
    name: String,
    filterKey: String,
    baseRange: Object,
    selected: Number,
});

const selectedValue = ref(selected);
const emit = defineEmits(['update']);

const formatCurrency = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 });
</script>

