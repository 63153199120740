<template>
    <div class="productSlider" style="position:relative;">
        <h2 style="padding-bottom: 16px;" class="text-lg font-bold">{{ headline }}</h2>

        <ScrollXVue>
            <a v-for="product in products" :href="'/p/' + product.id" class="productSlider-card">
                <div class="productSlider-top">
                    <div class="productSlider-img">
                        <img v-if="product.image" width="160" height="160" :src="product.image" :alt="product.title" />
                        <img v-else width="160" height="160" src="@/assets/no-img.jpeg" :alt="product.title" />
                    </div>
                    <div class="productSlider-title">
                        <span>{{ product.title }}</span>
                    </div>
                </div>
                <div class="productSlider-bottom">
                    <span class="productSlider-price">
                        {{ formatCurrency.format(product.price_amount / 100) }}*
                    </span>
                </div>
            </a>
        </ScrollXVue>
    </div>
</template>

<style lang="scss">
.productSlider-price {
    color: $text-color-primary;
    font-size: 18px;
    font-weight: $font-weight-heavy;
    line-height: 23px;
    -webkit-font-smoothing: antialiased;
    white-space: nowrap;
}

.productSlider-title {
    padding: 0 16px;
    color: $text-color-primary;
    max-width: 700px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    vertical-align: bottom;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.productSlider-img {
    position: relative;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    justify-content: center;
    padding: 8px;
    padding-top: 16px;
    width: 100%;
    overflow: hidden;
    transition: filter 0.2s linear 0s;

    &:hover>img {
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
    }

    &>img {
        max-width: 100%;
        height: auto;
        align-self: center;
        transition: transform 300ms;
    }
}

.productSlider-bottom {
    padding: 16px;
}

.productSlider-top {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 12px;
    flex-direction: column;
    display: flex;
    -webkit-column-gap: 12px;
    column-gap: 12px;
    row-gap: 12px;
    flex: 1 0 auto;
}

.productSlider-card {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    background-color: $background-primary;
    border-radius: 8px;
    width: 218px;
    display: flex !important;
    flex-direction: column;
    margin-bottom: 16px;
    align-self: stretch;
    flex-shrink: 0;
    scroll-snap-align: start;

    box-shadow: 0 2px 6px 0 $shadow-primary;
    transition: box-shadow .2s ease;
    margin-left: 12px;

    &:first-of-type {
        margin-left: 0px;
    }
}
</style>

<script>
import axios from "axios";
import ScrollXVue from "@/components/ScrollX.vue";

export default {
    data() {
        return {
            products: [],
            formatCurrency: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }),
        }
    },
    props: {
        categoryPath: String,
        headline: String
    },
    methods: {
        async fetchData() {
            let response = await axios.get("/api/recommendation?slugPath=" + this.categoryPath);
            this.products = response.data;
        }
    },
    created() {
        this.fetchData();
    },
    components: {
        ScrollXVue,
    }
}
</script>