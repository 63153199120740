<template>
    <ul class="home-grid">
        <template v-for="category in categories">
            <li v-if="category.icon" style="display:block;">
                <a :href="generateUrl(category.path)" class="categoryGrid-link">
                    <div class="icon-container">
                        <BaseIcon :name="category.icon" />
                    </div>
                    <p>{{ category.name }}</p>
                </a>
            </li>
        </template>
    </ul>
    <div class="home-block">
        <div style="padding-top: 2.5rem;">
            <ProductSliderVue categoryPath="computer-und-software/hardware" headline="PC Hardware" />
            <ProductSliderVue categoryPath="handy-und-telefon/handys-ohne-vertrag" headline="Smartphones" />
        </div>
    </div>
</template>

<style lang="scss">
.home-block {
    position: relative;

    &::before {
        content: '';
        background-color: #f4f5f9;
        box-shadow: inset 16px 46px 16px -46px rgba(32, 76, 136, 0.15);
        height: 100%;
        left: 50%;
        position: absolute;
        width: 98vw;
        transform: translate(-50%);
    }
}


.home-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding-top: 24px;

    @media (min-width: 376px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: 500px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media (min-width: 625px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    @media (min-width: 875px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    @media (min-width: 1000px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

.icon-container {
    background-color: #f6f6f6;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    font-size: 30px;
    transition: background-color 200ms ease-out 100ms;
    margin-bottom: 8px;
}


.categoryGrid-link {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 16px 8px;
    text-align: center;

    &:hover .icon-container {
        background-color: $brand60;
        color: #FFF;
    }

    &:hover p {
        text-decoration: underline;
    }

    & p {
        font-size: $text-size-s;
        font-weight: inherit;
        line-height: $text-lh-s;
        max-width: 700px;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
    }
}
</style>

<script>
import { SiteStore } from '@/stores/Site';
import BaseIcon from '@/components/BaseIcon.vue';
import NavigationData from '@/navigation.json';
import ProductSliderVue from '@/components/ProductSlider.vue';

export default {
    setup() {
        const Site = SiteStore();
        return { Site }
    },
    data() {
        return {
            categories: NavigationData
        };
    },
    created() {
        this.Site.meta.title = 'ShoppingConnection - Die besten Preise, aufgrund der besten Connections';
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    methods: {
        generateUrl(path) {
            let categories = [];
            if (path.includes('/')) {
                categories = path.split('/');
            } else {
                categories.push(path);
            }
            return this.$router.resolve({ name: 'category', params: { categories: categories } }).href
        }
    },
    components: {
        BaseIcon,
        ProductSliderVue
    }
}
</script>