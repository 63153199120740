<template>
    <div class="homeHeader">
        <div class="pageContainer">
            <div class="homeHeader-content">
                <div class="homeHeader-navbar">
                    <div><img src="@/assets/logo-inverted.png" style="height:44px" class="homeHeader-logo" /></div>
                    <div class="homeHeader-menu">
                        <button @click="showNavigation = !showNavigation">
                            <span class="headerNavigation-mobile" style="font-size: 36px;">
                                <BaseIcon v-if="!showNavigation" name="Menu" />
                                <BaseIcon v-else name="Close" />
                            </span>
                            <span class="headerNavigation-desktop" style="font-size: 0.875rem;">
                                <span>Alle Kategorien</span>
                                <BaseIcon v-if="!showNavigation" name="ChevronDown" style="align-self: center;" />
                                <BaseIcon v-else name="Close" style="align-self: center;" />
                            </span>
                        </button>
                    </div>
                </div>
                <div v-if="!showNavigation" class="homeHeader-searchCard">
                    <div class="homeHeader-cardLogo">
                        <img src="@/assets/logo.png" style="height:36px" />
                    </div>
                    <div class="homeHeader-title text-4xl font-bold">
                        <h1>Vergleiche Preise und spare bares Geld mit den besten Connections</h1>
                    </div>
                    <div style="margin-top:1.5rem;">
                        <SearchForm />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <PageNavigation v-if="showNavigation" :showNavigation="showNavigation" />
    <div class="pageContainer">
        <div style="min-width: 0; width:100%;">
            <router-view></router-view>
        </div>
    </div>
    <PageFooter v-if="!showNavigation" />
</template>

<script>
import PageNavigation from '@/components/PageNavigation.vue';
import PageFooter from '@/components/PageFooter.vue';
import SearchForm from '@/components/SearchForm.vue';
import BaseIcon from '@/components/BaseIcon.vue';

export default {
    data() {
        return {
            showNavigation: false
        }
    },
    components: {
        PageNavigation,
        SearchForm,
        BaseIcon,
        PageFooter
    }
}
</script>

<style lang="scss">
.homeHeader-menu {
    color: #FFF;

    @media (min-width: 1024px) {
        background-color: $background-primary;
        border-radius: 4px;
        color: initial;
        padding: 12px 16px;
    }
}

.homeHeader-logo {
    display: block;

    @media (min-width:1024px) {
        display: none;
    }
}

.homeHeader-cardLogo {
    display: none;
    padding-bottom: 16px;

    @media (min-width:1024px) {
        display: block;
    }
}

.homeHeader-title {
    color: #FFF;

    @media (min-width: 1024px) {
        color: $text-color-primary;
    }
}

.homeHeader-searchCard {
    margin-top: 1rem;
    max-width: 700px;
    margin-bottom: 3rem;

    @media (min-width: 1024px) {
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 40px;
        background-color: $background-primary;
        border-radius: 1rem;
        box-shadow: 0 4px 8px 0 $shadow-primary, 0 0 1px 0 $shadow-primary;
    }
}

.homeHeader-navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.homeHeader {
    position: relative;
    width: 100%;
    background: url('@/assets/homeHeader.jpg') no-repeat;
    background-size: cover;
    background-position: center center;
}

.homeHeader-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.homeHeader::after {
    background-color: $overlay-primary;
    inset: 0px;
    content: "";
    position: absolute;
}
</style>