<script>
import { SiteStore } from '@/stores/Site';
import { useStoryblokApi, useStoryblokBridge } from '@storyblok/vue';

export default {
    setup() {
        const Site = SiteStore();
        return { Site }
    },
    data() {
        return {
            story: null,
        }
    },
    async created() {
        const storyblokApi = useStoryblokApi();
        const { data } = await storyblokApi.get(`cdn/stories/${this.$route.params.slug}`, { version: "draft" });
        this.story = data.story;
        console.log(this.story)
        useStoryblokBridge(this.story.id, (newStory) => (this.story = newStory));


        this.Site.meta.title = this.story.content.metatags.title ?? "";
        this.Site.meta.description = this.story.content.metatags.description ?? "";
    },
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>
 
<template>
    <StoryblokComponent v-if="story" :blok="story.content" />
</template>
