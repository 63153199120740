import { defineStore } from "pinia";
import axios from "axios";

export const SearchStore = defineStore({
    id: "search",
    state: () => ({
        query: "",
        category: null,
        sort_by: "relevance",
        sort_by_options: [
            { value: "relevance", translation: "Relevanz" },
            { value: "price_asc", translation: "Preis aufsteigend" },
            { value: "price_desc", translation: "Preis absteigend" },
        ],
        page: 1,
        filter: {
            categories: {
                name: "Kategorien",
                type: "single",
                values: [],
            },
            merchants: {
                name: "Händler",
                type: "multi",
                values: [],
            },
            brand: {
                name: "Marken",
                type: "multi",
                values: [],
            },
            price_amount: {
                name: "Preis",
                type: "number",
                value: undefined,
            },
            delivery_time_normalized: {
                name: "Verfügbarkeit",
                type: "single",
                translations: [
                    { value: "0", translation: "Keine Angabe" },
                    { value: "1", translation: "Sofort verfügbar" },
                    { value: "2", translation: "Demnächst verfügbar" },
                    { value: "3", translation: "Nicht verfügbar" },
                ],
                values: [],
            },
        },
        products: [],
        facets: {},
        total: 0,
        loading: false,
    }),
    getters: {},
    actions: {
        async loadMore() {
            this.page++;
            this.execute();
        },
        getRouting() {
            let name = "search";
            let params = {};
            let query = {};

            if (this.query) {
                query["q"] = this.query;
            }

            if (this.sort_by != "relevance") {
                query["sort_by"] = this.sort_by;
            }

            for (const [key, obj] of Object.entries(this.filter)) {
                if (
                    this.filter[key].values &&
                    this.filter[key].values.length >= 1 &&
                    key != "categories"
                ) {
                    if (
                        this.facets[key] &&
                        this.facets[key][0] &&
                        this.facets[key][0].data.length >= 1
                    ) {
                        let newValues = [];
                        this.filter[key].values.forEach((value) => {
                            this.facets[key][0].data.forEach((facetObj) => {
                                if (facetObj.value == value) {
                                    newValues.push(value);
                                }
                            });
                        });
                        if (newValues.length >= 1) {
                            query[key] = newValues.join(",");
                        }
                    }
                } else if (key == "price_amount") {
                    if (this.filter[key].value > 0) {
                        query["maxPrice"] = this.filter[key].value;
                    }
                }
            }

            if (
                this.filter.categories &&
                this.filter.categories.values.length >= 1
            ) {
                name = "category";
                params["categories"] =
                    this.filter.categories.values[0].split("/");
            }

            let routing = { name: name, params: params, query: query };
            return routing;
        },

        async execute() {
            this.loading = true;
            var requestBody = {
                page: this.page,
                filters: {
                    all: [],
                },
                facets: {
                    categories: [
                        {
                            type: "value",
                            sort: {
                                count: "desc",
                            },
                            size: 50,
                        },
                    ],
                    brand: [
                        {
                            type: "value",
                            sort: {
                                count: "desc",
                            },
                            size: 50,
                        },
                    ],
                    merchants: [
                        {
                            type: "value",
                            sort: {
                                count: "desc",
                            },
                            size: 50,
                        },
                    ],
                    delivery_time_normalized: [
                        {
                            type: "value",
                            sort: {
                                count: "desc",
                            },
                            size: 4,
                        },
                    ],
                    price_amount: [
                        {
                            type: "value",
                            sort: {
                                value: "desc",
                            },
                            size: 1,
                        },
                    ],
                },
            };

            if (this.query) {
                requestBody["query"] = this.query;
            }

            if (this.sort_by != "relevance") {
                let [sort_by, direction] = this.sort_by.split("_");
                requestBody["sort"] = {
                    "price_amount": direction
                };
            }

            for (const [key, obj] of Object.entries(this.filter)) {
                if (
                    (this.filter[key].type == "single" ||
                        this.filter[key].type == "multi") &&
                    this.filter[key].values.length >= 1
                ) {
                    var filter = {};
                    filter[key] = obj.values;
                    requestBody.filters.all.push(filter);
                } else if (this.filter[key].type == "number" && obj.value != undefined) {
                    var filter = {};
                    filter[key] = {
                        to: obj.value,
                    };
                    requestBody.filters.all.push(filter);
                }
            }


            let response = await axios.post("/api/search", requestBody)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                });


            if (this.page > 1) {
                this.products = this.products.concat(response.data.results);
                this.loading = false;
                return;
            }

            this.products = response.data.results;
            this.facets = response.data.facets;

            this.total = response.data.page.total_results;
            if (response.data.category) {
                this.category = response.data.category;
            } else {
                this.category = null;
            }

            this.loading = false;
        },
    },
});
