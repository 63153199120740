<template>
    <div style="position: relative;">
        <div class="headerSpacer"></div>
        <header class="headerWrapper">
            <div class="pageContainer">
                <div class="headerContent">
                    <div class="headerLogo">
                        <router-link :to="{ name: 'home' }">
                            <img src="@/assets/logo.png" style="height:40px;vertical-align: middle;" />

                        </router-link>
                    </div>
                    <div class="headerSearch">
                        <SearchForm :initialValue="initialSearchValue" />
                    </div>
                    <div class="headerNav">
                        <div class="headerNavigation">
                            <button class="headerNavigation-link" @click="showNavigation = !showNavigation">
                                <span class="headerNavigation-mobile" style="font-size: 36px;">
                                    <BaseIcon v-if="!showNavigation" name="Menu" />
                                    <BaseIcon v-else name="Close" />
                                </span>
                                <span class="headerNavigation-desktop">
                                    <span>Alle Kategorien</span>
                                    <BaseIcon v-if="!showNavigation" name="ChevronDown" style="align-self: center;" />
                                    <BaseIcon v-else name="Close" style="align-self: center;" />
                                </span>
                            </button>

                        </div>
                    </div>
                </div>

            </div>

        </header>
    </div>
    <PageNavigation v-if="showNavigation" :showNavigation="showNavigation" />
    <div class="pageContainer">
        <div style="min-width: 0; width:100%;display: flex;flex-flow: column;">
            <router-view></router-view>
        </div>
    </div>
    <PageFooter v-if="!showNavigation" />
</template>

<script>
import SearchForm from '@/components/SearchForm.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import PageNavigation from '@/components/PageNavigation.vue';
import PageFooter from '@/components/PageFooter.vue';

export default {
    data() {
        return {
            showNavigation: false,
            initialSearchValue: ""
        }
    },
    created() {
        if (this.$route.query.q) {
            this.initialSearchValue = this.$route.query.q;
        }
    },
    components: {
        SearchForm,
        BaseIcon,
        PageNavigation,
        PageFooter
    }
}
</script>

<style lang="scss">
.headerWrapper {
    z-index: 2;
    left: 0;

    top: 0;
    border-bottom: 1px solid #ebeff5;
    width: 100%;
    height: 110px;
    display: flex;
    background-color: #FFF;
    padding-bottom: 8px;

    @media (min-width: 650px) {
        height: 76px;
        padding-bottom: 0;
    }

    @media (min-width: 1024px) {
        position: fixed;
    }
}

.headerSpacer {
    height: 76px;
    display: none;

    @media (min-width: 1024px) {
        display: block;
    }
}

.headerLogo {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 1;
    order: 2;
    padding-right: 24px;
    width: 260px;
}

@media (min-width: 1024px) {
    .headerLogo {
        order: 1;
    }
}

.headerSearch {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    order: 3;
}

@media (min-width: 650px) {
    .headerSearch {
        padding-right: 24px;
        max-width: 500px;
        width: auto;
        order: 2;
    }
}

.headerNav {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 1;
    margin-left: auto;
    order: 2;

    @media (min-width: 650px) {
        order: 3;
    }
}

.headerNavigation {
    display: flex;
}

.headerContent {
    align-items: center;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.searchSubmit {
    background: transparent;
    border: none;
    cursor: pointer;
    justify-content: center;
    height: 100%;
    display: flex;
    width: 70px;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.searchSubmit:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.searchInput {
    display: flex;
    z-index: 3;
    height: 44px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06);
    border-radius: 3px;
    margin: 0 auto;
}

.searchInput input {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .87);
    word-wrap: break-word;
    outline: none;
    flex: 100%;
    -webkit-tap-highlight-color: transparent;
    height: 34px;
    font-size: 16px;
    align-self: center;
}

.headerNavigation-link {
    line-height: $text-lh-xl;
    font-size: $text-size-s;
    height: $text-lh-xl;
    font-weight: $font-weight-bold;
    cursor: pointer;

    & .baseIcon {
        color: $brand60;
    }

    &:hover span {
        color: $brand60;
    }
}

.headerNavigation-desktop {
    display: none;

    & span {
        display: inline-block;
        padding-right: 4px;
    }
}

@media (min-width: 1024px) {
    .headerNavigation-desktop {
        display: flex;
    }

    .headerNavigation-mobile {
        display: none;
    }
}
</style>