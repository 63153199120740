<template>
    <div>
        <div v-for="(facetOptions, key) in Search.facets" :key="key" class="filter">
            <template v-if="key == 'categories' && facetOptions[0].data.length >= 1">
                <span class="filter-title">{{ Search.filter[key].name }}</span>
                <div class="filter-section">
                    <template v-for="facetOption in facetOptions[0].data">
                        <label class="filter-tag" :class="{ 'filter-tag-disabled': (facetOption.count == 0) }"
                            @click="(facetOption.count >= 1) ? toggleFilter(key, facetOption.value) : null">
                            <span style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                                {{ categorySlugToName(facetOption.value) }}
                            </span>
                            <span>{{ formatNumbers.format(facetOption.count) }}</span>

                        </label>
                    </template>
                </div>
            </template>

            <template v-if="(key == 'price_amount')">
                <SearchFilterSlider :name="Search.filter[key].name" :filterKey="key"
                    :baseRange="{ min: 0, max: Search.facets.price_amount[0].data[0].value / 100 }"
                    :selected="Search.filter[key].value ?? Search.facets.price_amount[0].data[0].value / 100"
                    @update="toggleFilter" />
            </template>

            <template v-if="(key == 'brand') && facetOptions[0].data.length >= 1">
                <SearchFilterMultiCheckbox :name="Search.filter[key].name" :filterKey="key" :options="facetOptions[0].data"
                    :actives="Search.filter[key].values" @update="toggleFilter" />
            </template>

            <template v-if="(key == 'merchants') && facetOptions[0].data.length >= 1">
                <SearchFilterMultiCheckbox :name="Search.filter[key].name" :filterKey="key" :options="facetOptions[0].data"
                    :actives="Search.filter[key].values" @update="toggleFilter" />
            </template>

            <template v-if="(key == 'delivery_time_normalized') && facetOptions[0].data.length >= 1">
                <SearchFilterMultiCheckbox :name="Search.filter[key].name" :filterKey="key" :options="facetOptions[0].data"
                    :actives="Search.filter[key].values" @update="toggleFilter"
                    :translations="Search.filter[key].translations" />
            </template>

        </div>
    </div>
</template>

<script>
import { SearchStore } from '@/stores/Search';
import SearchFilterMultiCheckbox from './SearchFilterMultiCheckbox.vue';
import SearchFilterSlider from './SearchFilterSlider.vue';

export default {
    setup() {
        const Search = SearchStore();
        return { Search }
    },
    data() {
        return {
            formatNumbers: new Intl.NumberFormat('de-DE'),
            formatCurrency: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }),
            priceMax: 345
        }
    },
    methods: {
        categorySlugToName(path) {
            const obj = this.Search.category.children.find(item => item.path == path);
            return obj.name;
        },
        toggleFilter(group, value) {
            if (group == "price_amount") {
                this.Search.filter[group].value = value;
                this.$router.push(this.Search.getRouting());
                return;
            }

            const filter = this.Search.filter[group].values;
            const index = filter.indexOf(value);
            if (index > -1) {
                this.Search.filter[group].values.splice(index, 1);
            } else {
                if (group == "categories") {
                    this.Search.filter[group].values = [String(value)];
                } else {
                    this.Search.filter[group].values.push(String(value));
                }
            }
            this.$router.push(this.Search.getRouting());
            return;
        },
    },
    components: {
        SearchFilterSlider,
        SearchFilterMultiCheckbox
    }
}
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style lang="scss">
.slider-connect {
    background: $brand60;
}

.slider-tooltip {
    background: $brand60;
    border: 1px solid $brand60;
}

.filters {
    flex-direction: column;
    display: flex;
    -webkit-column-gap: 16px;
    column-gap: 16px;
    row-gap: 16px;
}

.filtersHeadline {
    max-width: 700px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 8px;
    letter-spacing: -0.2px;
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;
}

.filterContainer {
    display: none;
}

@media (min-width: 1024px) {
    .filterContainer {
        align-self: flex-start;
        display: block;
        flex: 0 0 236px;
        margin-right: 24px;
        width: 236px;
    }
}

.filter-tag {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    min-height: 24px;
    margin-bottom: 8px;
}

.filter-tag.filter-tag-active:before {
    background: #0771d0 url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23fff%27 viewBox=%270 0 24 24%27%3E %3Cpath d=%27M8.727,20h0a2,2,0,0,1-1.414-.586L1.859,13.959a2,2,0,0,1,2.828-2.828l4.04,4.04L19.313,4.586a2,2,0,0,1,2.828,2.828l-12,12A2,2,0,0,1,8.727,20Z%27/%3E %3C/svg%3E') center center no-repeat;
    background-size: 16px;
    border-color: #0771d0;
}

.filter-tag.filter-tag-active.filter-tag-disabled:before {
    background: $grayscale40 url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23fff%27 viewBox=%270 0 24 24%27%3E %3Cpath d=%27M8.727,20h0a2,2,0,0,1-1.414-.586L1.859,13.959a2,2,0,0,1,2.828-2.828l4.04,4.04L19.313,4.586a2,2,0,0,1,2.828,2.828l-12,12A2,2,0,0,1,8.727,20Z%27/%3E %3C/svg%3E') center center no-repeat;
    background-size: 16px;
    border-color: $grayscale40;
}

.filter-tag-disabled {
    color: $grayscale60;
    cursor: default;
}

.filter-tag:before {
    background-color: #fff;
    border: 1px solid #b4b4b4;
    border-radius: 2px;
    box-sizing: border-box;
    content: "";
    display: block;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    transition: background-color 250ms, border-color 250ms, color 250ms;
    width: 24px;
}

.filter-tag-disabled:before {
    background-color: $grayscale40;
    border-color: $grayscale40;
}

.filter-title {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    line-height: 1.2;
    vertical-align: middle;
    color: #0a3761;
    fill: #0a3761;
    font-size: 18px;
    font-weight: bold;
    line-height: 40px;
}

.filter {
    margin-bottom: 18px;
}
</style>