<template>
    <div class="breadcrumb">
        <ol v-if="options.length >= 1">
            <li>
                <a href="/" class="breadcrumbLink">Start</a>
            </li>
            <template v-for="option in options">
                <li>
                    <span class="breadcrumbDelimiter">/</span>
                </li>
                <li>
                    <a :href="option.link" class="breadcrumbLink">{{ option.name }}</a>
                </li>
            </template>
        </ol>
    </div>
</template>

<script>

export default {
    name: 'Breadcrumb',
    props: {
        options: [Object]
    }
}
</script>

<style lang="scss">
.breadcrumbDelimiter {
    color: #a1a8b3;
    margin: 0 8px;
}

.breadcrumbLink {
    color: #282b30;
    font-size: .875rem;
    font-weight: 400;
}

.breadcrumbLink.current {
    color: #5b6370;
}

.breadcrumb {
    margin: 8px 0;
}

@media (min-width: 768px) {
    .breadcrumb {
        margin: 16px 0;
    }
}

.breadcrumb li {
    list-style: none;
    display: none;

    @media (min-width: 768px) {
        display: flex;
    }
}

.breadcrumb li:nth-last-child(-n+3) {
    display: flex;
}

.breadcrumb ol {
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
</style>
