<template>
    <div class="relative">
        <button class="px-4 py-2" @click="isOpen = !isOpen" v-click-outside="close">
            {{ prefix ? prefix : '' }}{{ selected ? selected.translation : 'Select an option' }}
            <svg class="h-4 w-4 fill-current inline-block" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512">
                <path fill=" none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
                    d="M112 184l144 144l144-144">
                </path>
            </svg>
        </button>
        <div class="absolute left-0 w-full mt-2" v-show="isOpen" style="z-index: 1;">
            <div class="py-2 bg-white rounded-md shadow-lg">
                <button v-for="item in items" :key="item.value" @click.stop="select(item)"
                    class="block px-4 py-2 hover:bg-blue-500 hover:text-white w-full text-left"
                    :class="{ 'bg-slate-100': selected && item.value === selected.value }">
                    {{ item.translation }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue';
import { ClickOutside } from './directives/click-outside.js';

export default {
    directives: {
        ClickOutside
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        modelValue: {
            type: String,
            required: true
        },
        prefix: {
            type: String,
            required: false,
        },
    },
    setup(props, { emit }) {
        const isOpen = ref(false);
        let selected = ref(null);

        watch(
            () => props.modelValue,
            (newVal) => {
                selected.value = props.items.find(item => item.value === newVal);
            },
            { immediate: true }  // Dies stellt sicher, dass der Watcher bei der Initialisierung ausgeführt wird
        );

        const open = () => {
            isOpen.value = true;
        };

        const close = () => {
            isOpen.value = false;
        };

        const select = (item) => {
            selected.value = item;
            close();
            emit('update:modelValue', item.value);
        };

        return {
            isOpen,
            selected,
            open,
            close,
            select,
            prefix: props.prefix,
        };
    },
};
</script>
