import { defineStore } from "pinia";

export const SiteStore = defineStore({
  id: "site",
  state: () => ({
    meta: {
      title: "",
      description: "",
    },
    showFilter: false,
    showNavigation: false,
  }),
  getters: {},
  actions: {},
});
