<template>
    <teleport to="body">
        <FeedbackModal />
    </teleport>
    <footer class="footer">
        <div class="pageContainer">
            <div class="footer-parts">
                <div class="footer-desc">
                    <div
                        style="-webkit-font-smoothing: antialiased;font-weight: 600;margin-bottom: 8px;font-size: 1.5rem;line-height: 32px;letter-spacing: -0.2px;">
                        Was ist ShoppingConnection?
                    </div>
                    <div class="text-sm">
                        <p class="mb-2">ShoppingConnection ist ein neuer Preisvergleich, der seinen
                            Nutzern eine bessere Erfahrung als herkömmliche
                            Preisvergleichsseiten bieten will und gemeinsam mit seiner Community hilfreiche und
                            einzigartige Funktionen entwickelt.</p>
                        <p>ShoppingConnection ist völlig unabhängig und kostenlos zu nutzen. Unsere Vision ist es,
                            Einzigartigkeit in den
                            E-Commerce zurückzubringen</p>
                    </div>
                </div>
                <div class="footer-links">
                    <div
                        style="-webkit-font-smoothing: antialiased;font-weight: 600;margin-bottom: 8px;font-size: 1.5rem;line-height: 32px;letter-spacing: -0.2px;">
                        Weitere Informationen
                    </div>
                    <ul>
                        <li style="margin-bottom: 8px;">
                            <a href="/t/kontakt">Impressum</a>
                        </li>
                        <li style="margin-bottom: 8px;">
                            <a href="/t/datenschutz">Datenschutz</a>
                        </li>
                        <li style="margin-bottom: 8px;">
                            <a href="/t/geschaeftsbedingungen">Geschäftsbedingungen</a>
                        </li>
                    </ul>
                </div>
                <div class="footer-legal">
                    <img src="@/assets/logo-inverted.png" style="height:40px;" />
                    <div style="font-size: 0.875rem;">Copyright {{ new Date().getFullYear() }} Shopping Connection GmbH
                    </div>
                    <small style="display:inline-block;color: #999;padding-top: 1rem;">
                        * Alle Preise sind in Euro angegeben und enthalten die gesetzliche Mehrwertsteuer. Die
                        Versandkosten sind nicht enthalten.
                        Alle Preise, Rankings, Lieferzeiten und Versandkosten können zwischenzeitlichen Änderungen
                        unterliegen. Die oben
                        angegebenen Preise können in der Zwischenzeit höher sein.
                    </small>
                </div>
            </div>
        </div>
    </footer>
</template>

<style lang="scss">
.footer-parts {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-desc {
    order: 2;

    @media (min-width:768px) {
        max-width: 70%;
    }

    @media (min-width:1024px) {
        max-width: 40%;
        order: 1;
    }
}

.footer-links {
    order: 1;

    @media (min-width:768px) {
        max-width: 30%;
    }

    @media (min-width:1024px) {
        order: 2;
    }
}

.footer-legal {
    order: 3;

    @media (min-width:1024px) {
        max-width: 30%;
    }
}

.footer-desc,
.footer-links,
.footer-legal {
    padding-bottom: 2rem;
}

.footer {
    color: $grayscale20;
    background-color: rgb(40, 43, 48);
    margin-top: auto;
    padding: 3rem 0 1rem 0;
}

.footer a:hover {
    color: #FFF;
    text-decoration: underline;
}
</style>

<script>
import FeedbackModal from '@/components/FeedbackModal.vue';
export default {
    components: {
        FeedbackModal,
    }
}
</script>