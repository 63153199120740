<template>
    <ul v-if="countOptionsWithCount >= 1" class="filterCarousel">
        <template v-for="option in options">
            <li v-if="option.count > 0" class="filterCarousel-item" @click="$emit('item-clicked', option.group, option.id)">
                <span v-if="option.img" class="filterCarousel-img">
                    <img class="xUAmIg3IAt YbZTMh14SC pr-0" style="width:100%" :src="option.img">
                </span>
                <span>{{ option.name }}</span>
                <small style="font-weight: bold;padding-left: 12px;">
                    {{ formatNumbers.format(option.count) }}
                </small>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    emits: [
        'item-clicked'
    ],
    props: {
        options: Array
    },
    computed: {
        countOptionsWithCount() {
            return this.options.filter(obj => obj.count > 0).length
        }
    },
    data() {
        return {
            formatNumbers: new Intl.NumberFormat('de-DE')
        }
    },
}
</script>

<style lang="scss">
.filterCarousel-img {
    width: 34px;
    height: 34px;
    display: block;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;

    &::after {
        background-color: $overlay-image;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0px;
    }
}

.filterCarousel {
    -webkit-overflow-scrolling: touch;
    align-items: center;
    display: flex;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    scroll-padding-left: 8px;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    margin-top: 8px;
    margin-bottom: 16px;

    &::-webkit-scrollbar {
        width: 0 !important
    }
}

.filterCarousel-item {
    padding: 12px 12px 12px 8px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
    position: relative;
    flex-shrink: 0;
    height: 48px;
    border-radius: 4px;
    white-space: nowrap;
    transition: box-shadow 300ms ease 0s, background 300ms ease 0s, color 300ms ease 0s, transform 300ms ease 0s;
    font-weight: $font-weight-normal;
    box-shadow: 0 0 0 1px inset $border40;
    font-size: $text-size-s;
    margin-right: 8px;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 0 1px inset $border100;
    }
}
</style>