<script>
import { h, resolveComponent, Text } from 'vue'

const nativeTypes = ['button', 'submit', 'reset'];
const availableSizes = ['sm', 'md', 'lg'];
const availableVariants = ['primary', 'primary-outline', 'secondary', 'secondary-outline'];

export default {
    inheritAttrs: false,
    props: {
        active: { type: Boolean, default: false },
        block: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        href: { type: String },
        loading: { type: Boolean, default: false },
        type: { type: String, validator: (value) => nativeTypes.includes(value) },
        size: { type: String, default: 'md', validator: (value) => availableSizes.includes(value) },
        target: { type: String, default: "_self" },
        variant: { type: String, default: null, validator: (value) => availableVariants.includes(value) },
        to: { type: Object },
    },

    render() {
        var tag = "button";
        var props = {
            class: {
                'v-btn': true,
                [`v-btn--${this.variant}`]: this.variant,
                [`v-btn--${this.size}`]: this.variant ? this.size : undefined,
                'v-btn--active': this.active,
                'v-btn--block': this.block,
                'v-btn--disabled': this.disabled,
                'v-btn--loading': this.loading
            },
            onClick: (value) => this.$emit('click', value)
        };

        if (this.href) {
            tag = "a";
            props.href = this.href;
            props.target = this.target;
        }

        if (this.to) {
            tag = resolveComponent('router-link');
            props.to = this.to;
        }

        if (tag == "button" && this.type) { props.type = this.type }

        return h(tag, props, {
            default: () => {
                const items = this.$slots.default().map(slotItem => {
                    // Wrap text into span element to position everything with flex
                    if (slotItem.type == Text) {
                        return h('span', {}, [slotItem.children.trim()])
                    }
                    return slotItem;
                })
                return items

            }
        })
    }
}
</script>

<style lang="scss">
.v-btn {
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    white-space: nowrap;
    transition: background 300ms ease 0s, color 300ms ease 0s, box-shadow 300ms ease 0s;
}

.v-btn>*:not(:last-child) {
    margin-right: 0.5em;
}

.v-btn>.v-icon {
    font-size: 1.2em;
}

.v-btn--sm {
    font-size: 0.875em;
    padding: 0.75em 1em;
    font-weight: $font-weight-medium;
}

.v-btn--md {
    font-size: 1em;
    padding: 0.75em 1.5em;
    font-weight: $font-weight-medium;
}

.v-btn--lg {
    font-size: 1.25em
}

.v-btn--primary {
    border-radius: 4px;
    background-color: $brand60;
    color: $grayscale0;

    &.v-btn--active,
    &:hover {
        background: $brand80;
    }

    &-outline {
        border-radius: 4px;
        box-shadow: 0 0 0 1px inset $brand60;
        color: $brand60;

        &.v-btn--active,
        &:hover {
            box-shadow: 0 0 0 1px inset $brand60;
            background: $brand60;
            color: $grayscale0;
        }
    }
}

.v-btn--secondary {
    border-radius: 4px;
    background-color: $grayscale60;
    color: $grayscale0;

    &.v-btn--active,
    &:hover {
        background: $grayscale80;
    }

    &-outline {
        border-radius: 4px;
        box-shadow: 0 0 0 1px inset $grayscale60;
        color: $grayscale80;

        &.v-btn--active,
        &:hover {
            box-shadow: 0 0 0 1px inset $grayscale60;
            background: $grayscale60;
            color: $grayscale0;
        }
    }
}

.v-btn--block {
    display: flex;
    width: 100%;
}

.v-btn--disabled {
    cursor: not-allowed;
}

.v-btn--loading {
    pointer-events: none;
    color: transparent;

    &::after {
        animation: spin 0.5s infinite linear;
        border-radius: 50%;
        border: 2px solid currentColor;
        border-right-color: transparent;
        border-top-color: transparent;
        content: '';
        display: block;
        height: 1em;
        left: calc(50% - 0.5em);
        position: absolute;
        top: calc(50% - 0.5em);
        width: 1em;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>