<template>
    <div class="scrollX">
        <slot></slot>
    </div>

</template>

<script>
export default {

}
</script>

<style lang="scss">
.scrollX {
    -webkit-overflow-scrolling: touch;
    align-items: center;
    display: flex;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    scroll-padding-left: 8px;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0 !important
    }
}
</style>