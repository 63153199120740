
<template>
  <Suspense>
    <template #default>
      <component :is="layout" />
    </template>

    <template #fallback>
      <div>Loading...</div>
    </template>
  </Suspense>
</template>

<script>
import LayoutClassic from '@/components/layouts/LayoutClassic.vue';
import LayoutHome from '@/components/layouts/LayoutHome.vue';
import { SiteStore } from '@/stores/Site';
import { useHead } from "@vueuse/head";
import './assets/css/tailwind.css';

export default {
  setup() {
    const Site = SiteStore();
    useHead({
      // Can be static or computed
      title: () => Site.meta.title,
      meta: [
        {
          name: `description`,
          content: () => Site.meta.description,
        },
      ],
    });
    return { Site }
  },
  data() {
    return {
      layout: null,
    }
  },
  watch: {
    $route(to) {
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout
      } else {
        this.layout = "LayoutClassic"
      }
    },
  },
  components: {
    LayoutClassic,
    LayoutHome
  }
}
</script>

<style lang="scss">
#app {
  flex-flow: column;
  display: flex;
  height: 100vh;
}

.tooltip {
  &::after {
    background: rgba(48, 55, 66, .95);
    border-radius: 0.1rem;
    bottom: 100%;
    color: #fff;
    content: attr(data-tooltip);
    display: block;
    font-size: .875rem;
    left: 50%;
    max-width: 320px;
    opacity: 0;
    overflow: hidden;
    padding: 0.2rem 0.4rem;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    transform: translate(-50%, 0.4rem);
    transition: opacity .2s, transform .2s;
    white-space: pre;
    z-index: 10;
  }

  &:focus:after,
  &:hover::after {
    opacity: 1;
    transform: translate(-50%, -0.2rem);
  }
}


.pageContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  max-width: 1380px;
  padding: 0 8px;
}

@media (min-width: 360px) {
  .pageContainer {
    padding: 0 16px;
  }
}

@media (min-width: 768px) {
  .pageContainer {
    padding: 0 24px;
  }
}

@media (min-width: 1440px) {
  .pageContainer {
    padding: 0 40px;
  }
}
</style>
