<template>
    <div v-editable="blok" class="bg-white py-16 px-6 lg:px-8">
        <div class="mx-auto max-w-5xl text-base leading-7 text-gray-700">
            <p v-if="blok.eyebrown" class="text-base font-semibold leading-7 text-indigo-600">{{ blok.eyebrown }}</p>
            <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ blok.headline }}</h1>
            <p v-if="blok.description" class="mt-6 text-xl leading-8">{{ blok.description }}</p>
            <div class="richtext mt-10 max-w-1xl">
                <Vue3RuntimeTemplate :template="resolvedRichText"></Vue3RuntimeTemplate>
            </div>
        </div>
    </div>
</template>
 
<script setup>
import { computed } from 'vue';
import { renderRichText } from '@storyblok/vue';
import Vue3RuntimeTemplate from "vue3-runtime-template"
const props = defineProps({ blok: Object })
const resolvedRichText = computed(() => renderRichText(props.blok.content, {
    resolver: (component, blok) => {
        return `<component :blok='${JSON.stringify(blok)}' is="${component}" />`
    },
}))

</script>