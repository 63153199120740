import { watch } from 'vue';
import { useRouter } from 'vue-router';
import { SearchStore } from '@/stores/Search';

export function watchSortBy() {
    const store = SearchStore();
    const router = useRouter();


    watch(() => store.sort_by, async (newSortValue, oldSortValue) => {
        if (newSortValue !== oldSortValue) {
            router.push(store.getRouting());
            console.log('Sort by changed to: ', newSortValue);
        }
    });
}