import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import { createPinia } from "pinia";
import { Vue3Mq } from "vue3-mq";
import { StoryblokVue, apiPlugin } from "@storyblok/vue";
import * as VueRouter from "vue-router";
import App from "./App.vue";
import Home from "./components/pages/Home.vue";
import ProductDetail from "@/components/pages/ProductDetail.vue";
import ProductListing from "@/components/pages/ProductListing.vue";
import ContentSotryblok from "@/components/pages/Storyblok.vue";
import StoryblokPage from "./components/storyblok/Page.vue";
import CenteredContentPage from "./components/storyblok/CenteredContentPage.vue";
import Grid from "./components/storyblok/Grid.vue";
import Teaser from "./components/storyblok/Teaser.vue";
import Feature from "./components/storyblok/Feature.vue";
import HeaderSection from "./components/storyblok/HeaderSection.vue";

const routes = [
    {
        path: "/",
        name: "home",
        meta: {
            layout: "LayoutHome",
        },
        component: Home,
    },
    {
        path: "/c",
        name: "search",
        component: ProductListing,
        props: (route) => ({ query: route.query.q }),
    },
    {
        path: "/c/:categories*",
        name: "category",
        component: ProductListing,
        props: true,
    },
    {
        path: "/p/:id",
        name: "product",
        component: ProductDetail,
        props: true,
    },
    {
        path: "/t/:slug",
        name: "content",
        component: ContentSotryblok,
    },
];

const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes: routes,
});

const app = createApp(App);
const pinia = createPinia();
const head = createHead();

app.component("CenteredContentPage", CenteredContentPage);
app.component("StoryblokPage", StoryblokPage);
app.component("Grid", Grid);
app.component("Teaser", Teaser);
app.component("Feature", Feature);
app.component("HeaderSection", HeaderSection);

app.use(router);
app.use(pinia);
app.use(head);
app.use(Vue3Mq);
app.use(StoryblokVue, {
    accessToken: "sbs7CjwuUwGQoLFS7RiRjAtt",
    use: [apiPlugin],
});
StoryblokVue.set;

app.mount("#app");
