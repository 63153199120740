import { defineStore } from "pinia";
import axios from "axios";

export const ProductStore = defineStore({
    id: "product",
    state: () => ({
        id: null,
        brand: null,
        mpn: null,
        title: null,
        description: null,
        image: null,
        categories: [],
        offers: [],
        loading: false,
    }),
    getters: {},
    actions: {
        async fetch(id) {
            this.loading = true;

            let response = await axios.get("/api/product/" + id);
            Object.entries(response.data).forEach((entry) => {
                const [key, value] = entry;
                if (key in this) {
                    this[key] = value;
                }
            });

            this.loading = false;
        },
    },
});
