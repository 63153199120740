<template>
    <span class="filter-title">{{ name }}</span>
    <div class="filter-section">
        <div v-if="options.length >= 8" class="multiCheckbox-search">
            <input type="text" class="multiCheckbox-searchInput" :placeholder="name + ' finden'" v-model="search" />
        </div>
        <template v-for="facetOption in sortedOptions">
            <label class="filter-tag"
                :class="{ 'filter-tag-disabled': (facetOption.count == 0), 'filter-tag-active': (actives && actives.includes(facetOption.value)) }"
                @click="(facetOption.count >= 1) ? $emit('update', filterKey, facetOption.value) : null">
                <span style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{
                        translate(facetOption.value)
                }}</span>
                <span>{{ formatNumbers.format(facetOption.count) }}</span>
            </label>
        </template>
        <BaseButton block variant="secondary-outline" size="sm" v-if="!search && options.length >= 8"
            @click="expanded = !expanded">
            <span v-if="!expanded">Zeige alle {{ options.length }}</span>
            <span v-else>Zeige weniger</span>
        </BaseButton>
    </div>
</template>

<style lang="scss">
.multiCheckbox-search {
    background-color: $grayscale0;
    border-radius: 4px;
    align-items: center;
    position: relative;
    border: 1px solid $border40;
    overflow: hidden;
    transition: border-color 0.2s ease 0s;
    margin-bottom: 8px;
}

.multiCheckbox-searchInput {
    background-color: $grayscale0;
    font-size: $text-size-s;
    line-height: 30px;
    text-align: left;
    outline: none;
    height: 30px;
    width: 100%;
    padding: 0px 12px;
    border-radius: 4px;
    -webkit-tap-highlight-color: transparent;
}
</style>

<script>
import BaseButton from '@/components/BaseButton.vue';

export default {
    data() {
        return {
            formatNumbers: new Intl.NumberFormat('en-GB'),
            expanded: false,
            search: ''
        }
    },
    emits: ['update'],
    props: {
        filterKey: String,
        name: String,
        options: Array,
        actives: Array,
        translations: Array,
    },
    computed: {
        sortedOptions() {
            if (this.search) {
                return [...this.options].sort((a, b) => a.value.localeCompare(b.value)).filter(el => el.value.toLowerCase().includes(this.search.toLocaleLowerCase()))
            }

            if (!this.expanded) {
                if (this.options.length >= 8) {
                    return [...this.options].sort((a, b) => a.value - b.value).slice(0, 5);
                } else {
                    return [...this.options].sort((a, b) => a.value - b.value)
                }
            }
            return [...this.options].sort((a, b) => a.value.localeCompare(b.value));
        }
    },
    methods: {
        translate(value) {
            if (!this.translations || this.translations.length < 1) {
                return value
            }

            const obj = this.translations.find(item => item.value == value);
            return obj.translation;
        },
        update() {
            this.$emit('update')
        }
    },
    components: {
        BaseButton
    }

}
</script>