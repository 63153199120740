<template>
    <Breadcrumb :options="breadcrumb" />

    <div class="productDetail-grid">
        <div class="productDetail-gridImg">
            <div class="productImages">
                <div class="productImage">
                    <div class="imageContainer">
                        <img v-if="Product.image" :src="Product.image" style="width: 100%; height:100%;" />
                        <img v-else src="@/assets/no-img.jpeg" style="width: 100%; height:100%;" />
                    </div>
                </div>
            </div>
        </div>
        <div class="productDetail-gridInfo">
            <h1 class="detailTitle">{{ Product.title }}</h1>
            <div v-if="Product.offers.length >= 2" class="detailPrices">
                Vergleichen Sie Preise von <strong>{{ formatCurrency.format(minPrice) }}</strong>* bis <strong>{{
                    formatCurrency.format(maxPrice)
                }}*</strong>
            </div>
        </div>
        <div class="productDetail-gridFilter">
            <span style="margin-right: 16px;">
                <BaseButton variant="secondary-outline" :active="offerFilterStock"
                    @click="offerFilterStock = !offerFilterStock">
                    <BaseIcon name="ShippingFast" />
                    Nur sofort lieferbar
                </BaseButton>
            </span>
            <BaseButton variant="secondary-outline" :active="offerFilterShipping"
                @click="offerFilterShipping = !offerFilterShipping">
                <BaseIcon name="Pricetags" />
                Preise inkl. Versand
            </BaseButton>
        </div>
    </div>

    <div class="secondPart">
        <div class="productOffers">
            <template v-for="offer in sortedOffers">
                <div class="offer" v-if="!offerFilterStock || (offerFilterStock && offer.delivery_time_normalized == 1)">
                    <div class="offer-title">
                        {{ offer.title }}
                    </div>
                    <div class="offer-infos">
                        <div class="offer-shipping">
                            <BaseIcon name="ShippingFast" class="tooltip"
                                :class="deliveryClass(offer.delivery_time_normalized)"
                                :data-tooltip="(offer.delivery_time_text) ? offer.delivery_time_text : 'Keine Angaben'" />
                        </div>
                        <div class="offer-merchant"><strong>{{ offer.merchant_name }}</strong></div>
                        <div class="offer-price">
                            <div style="font-size: 1.6em;font-weight:600;margin-bottom: 4px;">
                                {{ formatCurrency.format(price(offer.price_amount, offer.delivery_cost) / 100) }}*
                            </div>
                            <div v-if="offer.delivery_cost" style="font-size: 0.875em;">
                                <span v-if="offerFilterShipping">
                                    inkl.
                                </span>
                                <span v-else>
                                    zzgl.
                                </span>
                                {{ formatCurrency.format(offer.delivery_cost / 100) }} Versand
                            </div>
                            <div v-else style="font-size: 0.875em;">
                                Versand unbekannt
                            </div>
                        </div>
                        <div class="offer-clickout">
                            <BaseButton variant="primary" :href="offer.link" target="_blank">
                                Zum Shop
                                <BaseIcon name="ChevronForward" />
                            </BaseButton>
                        </div>
                    </div>
                </div>
            </template>
        </div>

    </div>
</template>

<style lang="scss">
.offer-title {
    white-space: nowrap;
    padding: 15px 45px 0 15px;
    font-size: 13px;
    line-height: 16px;
    max-width: 800px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.offer-infos {
    display: flex;
    padding: 0 15px 15px 15px;
    align-items: center;
    flex-wrap: wrap;
}

.offer-shipping {
    font-size: 1.6em;
    height: 1em;
    width: 3rem;
}

.offer-merchant {
    flex-grow: 1;
}

.offer-price {
    text-align: right;

    @media (min-width: 768px) {
        padding-right: 3rem;
    }
}

.offer-clickout {
    width: 100%;
    padding-top: 8px;

    @media (min-width: 768px) {
        width: 150px;
        padding-top: initial;
    }
}

.productDetail-grid {
    display: grid;
    grid-template-columns: min-content auto;
    margin: 16px 0;

    @media (min-width: 768px) {
        margin: 24px 0;
    }
}

.productDetail-gridImg {
    @media (min-width: 768px) {
        grid-row: span 2;
    }

}

.productDetail-gridFilter {
    grid-column: span 2;
    align-self: end;
    padding-top: 16px;

    @media (min-width: 768px) {
        grid-column: auto;
    }
}

.deliveryTime-now {
    color: $positive60;
}

.deliveryTime-later {
    color: $warning60;
}

.deliveryTime-not {
    color: $negative60;
}

.deliveryTime-unknown {
    color: $grayscale60;
}

.offer {
    padding: 0px;
    background-color: #FFF;
    border-radius: 4px;
    border-width: 0;
    box-shadow: 0 2px 6px 0 rgba(32, 76, 136, 0.15);
    transition: box-shadow .2s ease;
    margin-bottom: 16px;
}


.productOffers {
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
}

.secondPart {
    position: relative;
    flex: 1;
}

.secondPart::before {
    content: '';
    background-color: $grayscale10;
    box-shadow: inset 16px 46px 16px -46px rgba(32, 76, 136, 0.15);
    height: 100%;
    left: 50%;
    position: absolute;
    width: 98vw;
    transform: translate(-50%);
}

.detailTitle {
    display: block;
    margin: 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    padding-right: 40px;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: -0.3px;

    @media (min-width: 768px) {
        font-size: 1.75rem;
        -webkit-line-clamp: 3;
    }
}

.detailPrices {
    padding-top: 8px;
    font-size: 0.875rem;

    @media (min-width: 768px) {
        font-size: 1rem;
        padding-top: 40px;
    }
}


.imageContainer {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: filter .2s linear;
    cursor: pointer;
    width: 100%;

    @media (min-width: 768px) {
        padding: 24px;
        border-radius: 24px;

        &::after {
            background-color: rgba(40, 63, 75, 0.05);
            content: "";
            position: absolute;
            inset: 0px;
        }
    }
}



.productImage {
    height: 80px;
    width: 80px;
    position: relative;

    @media (min-width: 768px) {
        height: 300px;
        width: 300px;
        border-radius: 16px;
    }
}

.productImages {
    margin-right: 40px;
}


.offerList {
    margin: 1rem 0 0 0;
    padding: 0;
}

.offerItem {
    display: flex;
    list-style: none;
    border-bottom: 1px solid #EEE;
    padding-bottom: 8px;
    margin-bottom: 8px;
}

.callOut {
    color: #FFF;
    border-radius: 3px;
    padding: 6px 12px;
    background-color: #32ac77;
    font-weight: 400;
}
</style>

<script>
import BaseIcon from '@/components/BaseIcon.vue';
import BaseButton from '@/components/BaseButton.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import { ProductStore } from '@/stores/Product';
import { SiteStore } from '@/stores/Site';

export default {
    setup() {
        const Product = ProductStore();
        const Site = SiteStore();
        return { Site, Product }
    },
    data() {
        return {
            breadcrumb: [],
            offerFilterStock: false,
            offerFilterShipping: false,
            formatCurrency: new Intl.NumberFormat(undefined, { style: 'currency', currency: 'EUR' })
        }
    },
    created() {
        this.Product.fetch(this.id).then(() => {
            this.Site.meta.title = this.Product.title + ' @ ShoppingConnection';

            /*
                        this.breadcrumb = [];
                        if (this.Product.categories) {
                            this.Product.categories.forEach((crumb) => {
                                let categories = [];
                                if (crumb.path.includes('/')) {
                                    categories = crumb.path.split('/');
                                } else {
                                    categories.push(crumb.path)
                                }
                                this.breadcrumb.push({
                                    name: crumb.name,
                                    link: this.$router.resolve({ name: 'category', params: { categories: categories } }).href
                                });
                            });
                        }*/
        });
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    methods: {
        deliveryClass(deliveryTimeNormalized) {
            return {
                'deliveryTime-now': deliveryTimeNormalized == 1,
                'deliveryTime-later': deliveryTimeNormalized == 2,
                'deliveryTime-not': deliveryTimeNormalized == 3,
                'deliveryTime-unknown': !deliveryTimeNormalized
            }
        },
        price(price, deliveryCost) {
            if (this.offerFilterShipping && deliveryCost) {
                return (price + deliveryCost);
            }
            return price;
        }
    },
    computed: {

        sortedOffers() {
            return [...this.Product.offers].sort((a, b) => {
                // Standardmäßige Sortierung, falls keine Versandkosten verfügbar sind
                const aTotal = a.price_amount + (this.offerFilterShipping ? (a.delivery_cost || 0) : 0);
                const bTotal = b.price_amount + (this.offerFilterShipping ? (b.delivery_cost || 0) : 0);

                return aTotal - bTotal;
            });
        },
        minPrice() {
            if (this.Product.offers.length >= 1) {
                const offer = this.Product.offers.reduce(function (prev, curr) {
                    return prev.price_amount < curr.price_amount ? prev : curr;
                });
                return offer.price_amount / 100
            }
        },
        maxPrice() {
            if (this.Product.offers.length >= 1) {
                const offer = this.Product.offers.reduce(function (prev, curr) {
                    return prev.price_amount > curr.price_amount ? prev : curr;
                });
                return offer.price_amount / 100
            }
        },
    },
    props: {
        id: String
    },
    components: {
        Breadcrumb,
        BaseIcon,
        BaseButton
    }
}
</script>